
.root {
  /*background-color: #f4f6f8;*/
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 74px;
}

.contentContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.content {
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}
